"use strict";

require('core-js/stable');
require('regenerator-runtime/runtime');

const Vue = require('vue/dist/vue.common');
const VueCarousel = require('vue-carousel');

Vue.use(VueCarousel);

const App = require('./App.vue');

new Vue({
  render: h => h(App),
}).$mount('#app')
