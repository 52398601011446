<template>
    <div class="container" ref="mainContainer">
        <div class="carousel-container" ref="carousel">
            <div class="title">
                <h2>Restaurante Terraza-Bar</h2>
                <h1>El Aula</h1>
            </div>
            <carousel :per-page="1" :autoplay-timeout="3500" :autoplay="true" :loop="true" :pagination-enabled="false">
                <slide v-for="(img, index) of images" :key="index">
                    <picture>
                        <source style="max-width: 100%" type="image/webp" :srcset="img.webp">
                        <source style="max-width: 100%" type="image/jpeg" :srcset="img.jpg">
                        <img :src="img.jpg" style="max-width: 100%" alt="El Aula Restaurante">
                    </picture>
                </slide>
            </carousel>
        </div>
        <div class="who-we-are">
            <h3>Sobre Nosotros</h3>
            <p>El Restaurante El Aula se encuentra ubicado en el centro historico de la Villa de La Adrada, junto a la Posada Villa Maria ocupan una gran casa señorial con magnífico jardín, pequeño oasis en el mismo corazón de la localidad.</p>
            <p>El Restaurante El Aula pone a disposición del comensal una fabulosa cocina tradicional con un toque actual, siempre respetando y cuidando un producto de gran calidad.</p>
            <p>Nuestros clientes podran disfrutar de la variedad y calidad de platos tanto del menú de diario como del especial de fines de semana y festivos.</p>
            <p>También disponemos de una completa carta de raciones, donde no faltan carnes, nuestras ricas hamburguesas, tostas, ...</p>
            <p>Durante el verano no hay mejor lugar para cenar y tomar una copa que nuestra fabulosa terraza.</p>
            <p>No dudes en contactar con nosotros si deseas confeccionar un menú para una reunión o celebración.</p>
        </div>
        <div class="where-we-are">
            <h3>Dónde Estamos</h3>
            <div class="info">
                <span>
                    <img src="./resources/images/email.svg" alt="Contactar por email">
                    <a href="mailto:hola@elaularestaurante.es">hola@elaularestaurante.es</a>
                </span>
                <span>
                    <img src="./resources/images/location.svg" alt="Ubicación">
                    Calle las Escuelas, s/n, 05430. La Adrada.
                </span>
                <span>
                    <img src="./resources/images/phone.svg" alt="Contactar por teléfono">
                    <a href="tel:918672322">918672322</a>
                </span>
            </div>
        </div>
        <div class="map-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3042.920605049338!2d-4.638103284526452!3d40.29972177937898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd404402d8b2f201%3A0x115e6c855236661f!2sRestaurante+El+Aula!5e0!3m2!1ses!2ses!4v1547407420062"
                width="600"
                height="250"
                frameborder="0"
                style="border:0"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script>
"use strict";

const {Carousel, Slide} = require('vue-carousel');
const imagesDesktop = require('./resources/images/carousel/horizontal/*.*');
const imagesTouch = require('./resources/images/carousel/vertical/*.*');


module.exports = {
    name: "App",
    mounted() {
        if (this.isMobile()) {
            this.$nextTick(() => {
                setTimeout(() => {
                    const carouselWidth = this.$refs.carousel.clientWidth*this.$refs.mainContainer.clientHeight/this.$refs.carousel.clientHeight;
                    this.$refs.carousel.getElementsByClassName('VueCarousel-inner')[0].style.width= `${carouselWidth+5}px`;
                }, 100);
            });
        }
    },
    components: {
        Carousel,
        Slide
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    computed: {
        images() {
            const imagesDict = this.isMobile() ? imagesTouch : imagesDesktop;
            const imagesArr = Object.values(imagesDict).sort(() => Math.random() - 0.5);
            return imagesArr;
        }
    },
}
</script>


<style lang="scss" scoped>
$dark_brown: #423636;
$light_brown: #c7b496;

.container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.carousel-container {
    margin-bottom: 4rem;
    position: relative;

    @media screen and (min-width: 992px) {
        height: 100vh;
        overflow: hidden;
    }

    .title {
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 400px;
        left: calc(50% - 200px);
        top: 80%;

        h1,
        h2 {
            margin: 0 auto;
            padding: 0.25rem 0.75rem;
        }

        h1 {
            font-weight: 900;
            color: #fff;
            background-color: $dark_brown;
        }

        h2 {
            font-weight: 500;
            color: $dark_brown;
            background-color: $light_brown;
        }
    }
}

.where-we-are,
.who-we-are {
    color: $dark_brown;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    a {
        color: $dark_brown;
    }

    .info {
        display: flex;
        justify-content: space-between;
        padding: 0 8rem;

        @media screen and (max-width: 991px) {
            padding: 0 2rem;
            flex-direction: column;
            align-items: center;

            span {
                margin-bottom: 0.75rem;
            }
        }

        span {
            display: flex;
            align-items: center;

            img {
                margin-right: 0.75rem;
            }
        }
    }

    h3 {
        font-size: 1.75rem;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
}

.who-we-are {
    text-align: center;
}

.map-container {
    position: relative;
    padding-bottom: 30%; // This is the aspect ratio
    height: 0;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        padding-bottom: 50%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
